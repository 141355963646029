import React from "react";
import PropTypes from "prop-types";
import HelicoptersOptionsList from "../../../common/HelicoptersOptionsList";
import Button from "../../../UI/Button";
import { backLabel } from "../../../../constants/content";
import { ReactComponent as WarningIcon } from "../../../../assets/svg/warningIconCircle.svg";

function ExternalEnquiryFormHelicopterOptionsStep(props) {
  const { helicopterOptions, handleGoToInfo, handleGoToContact, handleOptionsBack, typeOfTrip, show, warningMessages } =
    props;
  if (!show) return "";

  return (
    <div className="gh-widget-external-enquiry-form-options">
      {helicopterOptions.length ? (
        <HelicoptersOptionsList
          helicoptersList={helicopterOptions}
          typeOfTrip={typeOfTrip}
          handleGoToInfo={handleGoToInfo}
          warningMessages={warningMessages}
        />
      ) : (
        <div className="gh-widget-enquiry-error-container">
          <span>
            <WarningIcon />
          </span>
          <div>
            For certain routes, additional details are needed to provide the best options. Proceed to Step 3 to leave your contact information so our team can assist you promptly.
          </div>
        </div>
      )}

      <div className="gh-widget-enquiry-buttons-wrapper">
        <Button
          type="button"
          color="light"
          variant="contained"
          onClick={handleOptionsBack}
        >
          {backLabel}
        </Button>

        {!helicopterOptions.length && (
          <Button
            type="button"
            color="success"
            variant="contained"
            onClick={handleGoToContact}
          >
            Contact
          </Button>
        )}
      </div>
    </div>
  );
}

ExternalEnquiryFormHelicopterOptionsStep.propTypes = {
  helicopterOptions: PropTypes.array,
  handleGoToInfo: PropTypes.func,
  handleGoToContact: PropTypes.func,
  show: PropTypes.bool,
  handleOptionsBack: PropTypes.func,
  typeOfTrip: PropTypes.string,
  warningMessages: PropTypes.object,
};
ExternalEnquiryFormHelicopterOptionsStep.defaultProps = {
  show: false,
};
export default ExternalEnquiryFormHelicopterOptionsStep;
